import React, { useState, useEffect } from 'react';
import { Link } from '@reach/router';

const Posts = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const getPosts = async () => {
      const resp = await fetch(
        'https://gorans-api.gorans-assessment.workers.dev/api/posts'
      );

      try {
        const fetchedPosts = await resp.json();
        setPosts(fetchedPosts);
      } catch (error) {
        console.log('Oh oh, something went wrong with the API call... ', error.message);
      }
    };

    getPosts();
  }, []);

  return (
    <div>
      <h1>Here are the posts:</h1>
      {
        posts.map((post) => (
          <div key={post.id}>
            <h2>
              <Link to={`/posts/${post.id}`}>{post.title}</Link>
            </h2>
          </div>
        ))
      }
    </div>
  );
};

export default Posts;
