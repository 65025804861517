import './App.css';
import { Router } from '@reach/router';

import Posts from './components/Posts/Posts';
import Post from './components/Posts/Post';

const App = () => {
  return (
    <Router>
      <Posts path='/' />
      <Post path='/posts/:id' />
    </Router>
  );
}

export default App;