import React, { useState, useEffect } from 'react';
import { Link } from '@reach/router';

const Post = ({ id }) => {
  const [singlePost, setSinglePost] = useState({});

  useEffect(() => {
    const getSinglePost = async () => {
      const singlePostResponse = await fetch(
        `https://gorans-api.gorans-assessment.workers.dev/api/posts/${id}`
      );

      try {
        const fetchedPost = await singlePostResponse.json();
        setSinglePost(fetchedPost);
      } catch (error) {
        console.log('Something went wrong while trying to fetch the post', error.message);
      }
    };

    getSinglePost();
  }, [id]);

  if (!Object.keys(singlePost).length) return <div />;

  return (
    <div>
      <h1>{singlePost.title}</h1>
      <p>{singlePost.content}</p>
      <p>
        <em>Published {new Date(singlePost.published_at).toLocaleString()}</em>
      </p>
      <p>
        <em>Published by {singlePost.username}</em>
      </p>
      <p>
        <Link to="/">Back to home </Link>
      </p>
    </div>
  );
};

export default Post
